body {
    background: #3ca8cf;
}

.billboard {
    width: 320px;
    top: -10px;
    text-align: center;
}

.billboard h3 {
    font-size: 42px;
    font-weight: bold;
    color: #fff;
    text-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 82px #0fa,
            0 0 92px #0fa,
            0 0 102px #0fa,
            0 0 151px #0fa;
    font-family: "Vibur", sans-serif;
    visibility: hidden;
    transition: opacity 0.1s ease;
}

.billboard h2 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    text-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 82px #0fa,
            0 0 92px #0fa,
            0 0 102px #0fa,
            0 0 151px #0fa;
    font-family: "Vibur", sans-serif;
    visibility: hidden;
}

.billboard h2.visible, .billboard .neon.visible {
    visibility: visible;
}


.neon {
    font-family: 'Monoton', cursive;
    font-size:36px;
    color: #FFD5FF;
    letter-spacing: 8px;
    text-shadow: 1px 0px 4px #FFD5FF, 2px 0px 4px #FFD5FF, 3px 0px 4px #FFD5FF, 2px 0px 3px #D42CCA, 2px 3px 15px #D42CCA, 2px 0px 15px, 5px 0px 125px, 20px 0vw 200px #D42CCA,40px 0vw 200px #D42CCA;
    visibility: hidden;
}


.visible .flicker-slow{
    animation: flicker 12s linear infinite;
}

.visible .flicker-fast{
    animation: flicker 8s linear infinite;
}

.visible .flicker-slower {
    animation: flicker2 20s linear infinite;
}

@keyframes flicker {
    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
        opacity: .99;
    }
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
        opacity: 0.4;
    }
}

@keyframes flicker2 {
    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
        opacity: .99;
    }
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
        opacity: 0.6;
    }
}

.bigscreen {
    font-family: "Playpen Sans", sans-serif;
    background: url("../../public/resources/sprites/world/bigscreen.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
}

.bigscreen p {
    background-color: #8c6027;
    color: transparent;
    text-shadow: -2px 0px 3px rgba(233,233,203,0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.track {
    background-color: rgba(0,0,0,0.05);
}

.slider {
    background-color: #bc9665;
    transition: width 3000ms
}
